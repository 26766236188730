<template>
  <v-navigation-drawer
    ref="navbar"
    rail
    expand-on-hover
    permanent
    @update:rail="collapsed = $event"
  >
    <v-list nav>
      <v-list-item
        class="my-1"
        density="compact"
        v-for="(item, index) in navItems"
        :key="index"
        :prepend-icon="item.icon"
        :title="item.title"
        :value="item.value"
        :class="{ selectedView: $route.name === item.title }"
        @click="navigateTo(item.title)"
      >
      </v-list-item>
    </v-list>

    <template v-slot:append v-if="!collapsed">
      <v-row class="pa-5 semibold" style="font-size: 12px" justify="center">
        Powered by
        <img
          class="pt-n3 ml-1"
          :src="'https://www.projectstudio.ai/wp-content/uploads/2024/09/logo.png'"
          alt="Project Studio"
          width="80"
        />
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { getNavItems } from "@/resources/nav-items";
export default {
  name: "NavigationDrawer",
  data() {
    return {
      navItems: getNavItems(),
      collapsed: true,
    };
  },

  methods: {
    navigateTo(title) {
      this.$router.push({ name: title });
    },
  },
};
</script>
